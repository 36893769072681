import React, { useEffect } from "react";
import Layout from "../Components/UI/Layout";
import styles from "../Styles/Pages/Sobre.module.css";
import FotoBio from "../Assets/Images/Ficheiro13.jpg";
import { Link } from "react-router-dom";
import Messenger from "../Components/UI/Messenger";
import Seo from "../Components/Seo";
import { motion } from "framer-motion";

const Sobre = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout>
        <Seo siteTitle="Sobre" />
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.sobrePage}
        >
          <section>
            <img src={FotoBio} alt="" />
            <div>
              <h1>Sérgio Gonçalves</h1>
              <p>
                Olá, sou o Sérgio Gonçalves, nasci a dia 16 de Novembro de 1994
                e sou natural de Ponte da Barca. A minha paixão por esta arte
                começou cedo, quando em 2011 realizei num curso de fotografia,
                onde aprendemos com uma simples caixa de sapatos a arte de
                fotografar. Desde aí, frequentei o Curso Profissional de
                Multimédia cujo conclui em 2014, no mesmo ano onde comecei a
                trabalhar na área. No ano de 2019, foi quando decidi ir mais
                longe e abrir a minha própria empresa, Sérgio Gonçalves
                Fotografia e Video, pois a vontade de por em prática as minhas
                ideias, criar a minha própria identidade e chegar mais longe era
                enorme. Na nossa empresa, dedicamos muito do nosso tempo aos
                nossos trabalhos e tentamos sempre deixar o cliente o mais à
                vontade possível para expor o que pretende de forma a lhe
                proporcionarmos os melhores resultados. Para além disso, estamos
                sempre abertos a novos desafios, inclusive fazemos serviços por
                todo o mundo, e o nosso principal objetivo é inovar e criar
                sempre o melhor conteúdo
              </p>
            </div>
          </section>
          <div className={styles.spanBtnSection}>
            <h4>Já Viste a Minha Loja?</h4>
            <Link to="/espacoloja">
              <button>Ver</button>
            </Link>
          </div>
          {/* <LightBox /> */}
        </motion.main>
        <Messenger />
      </Layout>
    </>
  );
};

export default Sobre;
