import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import CookieConsent from "react-cookie-consent";
import Seo from "./Seo";
import styles from "../Styles/Components/App.module.css";
import Layout from "./UI/Layout";
import RecentsGallery from "./RecentsGallery";
import "react-circular-progressbar/dist/styles.css";
import Messenger from "./UI/Messenger";
import Servicos from "./Servicos";
import { motion } from "framer-motion";
import VideoHeroNew from "./VideoHeroNew";
import Loading from "./UI/Loading";

const TRACKING_ID = "G-NJJL495351"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

ReactGA.initialize("G-NJJL495351");

ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //setLoading(true) while page is loading

    const handleLoad = () => {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    };

    window.onload = handleLoad;

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  return (
    <div>
      <Seo siteTitle="Home" />
      {loading ? (
        <Loading />
      ) : (
        <Layout>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CookieConsent
              buttonClasses={styles.btnCookie}
              buttonStyle={{
                background: "black",
                color: " white",
                padding: "1rem",
                fontSize: "1rem",
              }}
              buttonText="Aceitar cookies adicionais"
              overlay="true"
              overlayStyle={{
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                opacity: "1",
              }}
            >
              <div className={styles.cookies}>
                <h3>Cookies neste site</h3>
                <p>
                  Utilizamos cookies essenciais para o nosso site funcionar e
                  lhe proporcionar melhor experiência de utilização. Gostaríamos
                  de utilizar cookies adicionais para compreender como utiliza o
                  nosso site e recordar as suas definições. Também utilizamos
                  cookies de terceiros/outros sites que ajudam a partilhar
                  conteúdos do site. Para obter mais informação consulte a
                  nossa.
                  <a href="/"> Política de privacidade</a>.
                </p>
              </div>
            </CookieConsent>

            <Seo siteTitle="Home" />
            <VideoHeroNew />
            <RecentsGallery />
            <Servicos />
          </motion.div>
        </Layout>
      )}
      <Messenger />
    </div>
  );
};

export default App;
