import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import styles from "../Styles/Components/Galeria.module.css";
import disableScroll from "disable-scroll";
import ModalRecents from "../Components/UI/ModalRecents";
import { motion } from "framer-motion";

const fotografiasData = gql`
  query {
    allFotografia(sort: { _createdAt: DESC }, limit: 8) {
      title
      image {
        url
        width
        height
      }
      _id
    }
  }
`;

const RecentsGallery = () => {
  const { data } = useQuery(fotografiasData);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const ScrollVariants = {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1,

      transition: {
        type: "spring",
        duration: 2,
        stiffness: 80,
        delay: 0.5,
      },
    },
  };

  function openModal(index) {
    setModalIndex(index);
    setModalOpen(true);
    disableScroll.on();
  }

  function closeModal() {
    setModalOpen(false);
    disableScroll.off();
  }
  function ImageGridItem(url, width, height, id, index) {
    const style = {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    };

    return (
      <>
        <motion.img
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={ScrollVariants}
          key={id}
          onClick={() => openModal(index)}
          style={style}
          src={url}
          alt={""}
        />
      </>
    );
  }

  return (
    <main className={styles.Galeria}>
      <section>
        <motion.h2
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={ScrollVariants}
          className={styles.serviçosTitle}
        >
          Trabalhos Recentes
        </motion.h2>
        <div className={styles.grid}>
          {data?.allFotografia &&
            data?.allFotografia.map((foto, index) => {
              return ImageGridItem(
                foto.image.url,
                foto.image?.width,
                foto.image?.height,
                foto._id,
                index
              );
            })}
        </div>

        {modalOpen && (
          <ModalRecents
            images={data?.allFotografia}
            startIndex={modalIndex}
            onClose={closeModal}
          />
        )}
      </section>
    </main>
  );
};

export default RecentsGallery;
