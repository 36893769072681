import React from 'react';
import Seo from '../Components/Seo';
import Layout from '../Components/UI/Layout';
import Messenger from '../Components/UI/Messenger';
import styles from '../Styles/Pages/404.module.css';

const NotFoundPage = () => {
  return (
    <>
      <Layout>
        <Seo siteTitle="Página não encontrada" />
        <main className={styles.main404}>
          <h1>Page Not Found</h1>
        </main>
      </Layout>
      <Messenger />
    </>
  );
};

export default NotFoundPage;
