import React from "react";
import { Icon } from "@iconify/react";
import { useQuery, gql } from "@apollo/client";
import styles from "../Styles/Components/Servicos.module.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const servicosData = gql`
  query {
    allServicos {
      title
      desc
      imagem {
        asset {
          url
        }
      }
    }
  }
`;

const Servicos = () => {
  const { data } = useQuery(servicosData);
  const ScrollVariants = {
    offscreen: {
      opacity: 0,
      x: -100,

      // x: -400,
    },
    onscreen: {
      opacity: 1,
      x: 0,

      // x: 0,
      transition: {
        type: "spring",
        duration: 0.7,
        stiffness: 80,
      },
    },
  };
  const ScrollVariantsLeft = {
    offscreen: {
      opacity: 0,
      x: 100,

      // x: -400,
    },
    onscreen: {
      opacity: 1,
      x: 0,

      // x: 0,
      transition: {
        type: "spring",
        duration: 0.7,
        stiffness: 80,
      },
    },
  };
  const ScrollVariantsDelay = {
    offscreen: {
      opacity: 0,
      y: 100,

      // x: -400,
    },
    onscreen: {
      opacity: 1,
      y: 0,

      // x: 0,
      transition: {
        type: "spring",
        duration: 0.7,
        stiffness: 80,
      },
    },
  };
  return (
    <motion.section className={styles.servicosPage}>
      <motion.h2
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: false, amount: 0.4 }}
        variants={ScrollVariantsDelay}
        className={styles.serviçosTitle}
      >
        Os Meus Serviços
      </motion.h2>
      <div className={styles.fotografiaFantastica} data-scroll-section>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={ScrollVariants}
          className={styles.fotoSection}
        >
          <div className={styles.gridReverse}>
            <Icon className={styles.icon} icon="ic:baseline-photo-camera" />
            <h2>{data?.allServicos[6].title}</h2>
            <p>{data?.allServicos[6].desc}</p>
          </div>
          <Link to="/Fotos?filtro=recem-nascido">
            <img src={data?.allServicos[6].imagem.asset.url} alt="" />
          </Link>
        </motion.div>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={ScrollVariantsLeft}
          className={styles.fotoSectionE}
        >
          <div>
            <Icon className={styles.icon} icon="ic:baseline-photo-camera" />
            <h2>{data?.allServicos[5].title}</h2>
            <p>{data?.allServicos[5].desc}</p>
          </div>
          <Link to="/Fotos?filtro=gravidez">
            <img src={data?.allServicos[5].imagem.asset.url} alt="" />
          </Link>
        </motion.div>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={ScrollVariants}
          className={styles.fotoSection}
        >
          <div>
            <Icon className={styles.icon} icon="ic:baseline-photo-camera" />
            <h2>{data?.allServicos[4].title}</h2>
            <p>{data?.allServicos[4].desc}</p>
          </div>
          <Link to="/Fotos?filtro=retrato">
            <img src={data?.allServicos[4].imagem.asset.url} alt="" />
          </Link>
        </motion.div>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={ScrollVariantsLeft}
          className={styles.fotoSectionE}
        >
          <div>
            <Icon className={styles.icon} icon="ic:baseline-photo-camera" />
            <h2>{data?.allServicos[3].title}</h2>
            <p>{data?.allServicos[3].desc}</p>
          </div>
          <Link to="/Fotos?filtro=promocionais">
            <img src={data?.allServicos[3].imagem.asset.url} alt="" />
          </Link>
        </motion.div>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={ScrollVariants}
          className={styles.fotoSection}
        >
          <div>
            <Icon className={styles.icon} icon="ic:baseline-photo-camera" />
            <h2>{data?.allServicos[2].title}</h2>
            <p>{data?.allServicos[2].desc}</p>
          </div>
          <Link to="/Fotos?filtro=casamento">
            <img src={data?.allServicos[2].imagem.asset.url} alt="" />
          </Link>
        </motion.div>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={ScrollVariantsLeft}
          className={styles.fotoSectionE}
        >
          <div>
            <Icon className={styles.icon} icon="ic:baseline-photo-camera" />
            <h2>{data?.allServicos[1].title}</h2>
            <p>{data?.allServicos[1].desc}</p>
          </div>
          <Link to="/Albuns">
            <img src={data?.allServicos[1].imagem.asset.url} alt="" />
          </Link>
        </motion.div>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={ScrollVariants}
          className={styles.fotoSection}
        >
          <div>
            <Icon className={styles.icon} icon="ic:baseline-photo-camera" />
            <h2>{data?.allServicos[0].title}</h2>
            <p>{data?.allServicos[0].desc}</p>
          </div>
          <img src={data?.allServicos[0].imagem.asset.url} alt="" />
        </motion.div>
        {/* ------------division----------  */}
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: false, amount: 0.4 }}
          variants={ScrollVariantsDelay}
          className={styles.spanBtnSection}
        >
          <h4>Veja os Meus Trabalhos Mais Recentes</h4>
          <a href="#trabalhos_recentes">
            <button>Ver...</button>
          </a>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Servicos;
