import React, { useState, useEffect } from "react";
import styles from "../Styles/Pages/Fotos.module.css";
import { useQuery, gql } from "@apollo/client";
import disableScroll from "disable-scroll";
import Modal from "../Components/UI/Modal";
import { useNavigate, useLocation } from "react-router-dom";

const fotografiasData = gql`
  query {
    allFotografia(sort: { _createdAt: DESC }) {
      title
      image {
        url
        width
        height
      }
      genre
      _id
    }
  }
`;

const GaleriaFotos = () => {
  const { data } = useQuery(fotografiasData);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const [showAll, setShowAll] = useState(true);
  const [retrato, setRetrato] = useState(false);
  const [promocionais, setPromocionais] = useState(false);
  const [gravidez, setGravidez] = useState(false);
  const [recemNascido, setRecemNascido] = useState(false);
  const [batizado, setBatizado] = useState(false);
  const [casamento, setCasamento] = useState(false);
  const [criancas, setCriancas] = useState(false);

  const [activeShowAll, setActiveShowAll] = useState(true);
  const [activeRetrato, setActiveRetrato] = useState(false);
  const [, setActivePromocionais] = useState(false);
  const [activeGravidez, setActiveGravidez] = useState(false);
  const [activeRecemNascido, setActiveRecemNascido] = useState(false);
  const [activeBatizado, setActiveBatizado] = useState(false);
  const [activeCasamento, setActiveCasamento] = useState(false);
  const [activeCriancas, setActiveCriancas] = useState(false);

  const [dataSelected, setDataSelected] = useState(null);

  const [numImages, setNumImages] = useState(20);

  const navigate = useNavigate();
  const location = useLocation();
  const filtro = new URLSearchParams(location.search).get("filtro");

  //set filter arrays for each category

  const retratoArray = data?.allFotografia.filter(
    (fotografia) => fotografia.genre === "retrato"
  );

  const promocionaisArray = data?.allFotografia.filter(
    (fotografia) => fotografia.genre === "promocionais"
  );
  const gravidezArray = data?.allFotografia.filter(
    (fotografia) => fotografia.genre === "gravidez"
  );
  const recemNascidoArray = data?.allFotografia.filter(
    (fotografia) => fotografia.genre === "recem-nascido"
  );
  const batizadoArray = data?.allFotografia.filter(
    (fotografia) => fotografia.genre === "batizado"
  );
  const casamentoArray = data?.allFotografia.filter(
    (fotografia) => fotografia.genre === "casamento"
  );
  const criancasArray = data?.allFotografia.filter(
    (fotografia) => fotografia.genre === "criancas"
  );

  const ButtonComponent = () => {
    if (showAll && numImages < data?.allFotografia?.length) {
      return (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Ver mais
        </button>
      );
    } else if (retrato && numImages < retratoArray?.length) {
      return (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Ver mais
        </button>
      );
    } else if (promocionais && numImages < promocionaisArray?.length) {
      return (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Ver mais
        </button>
      );
    } else if (gravidez && numImages < gravidezArray?.length) {
      return (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Ver mais
        </button>
      );
    } else if (recemNascido && numImages < recemNascidoArray?.length) {
      return (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Ver mais
        </button>
      );
    } else if (batizado && numImages < batizadoArray?.length) {
      return (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Ver mais
        </button>
      );
    } else if (casamento && numImages < casamentoArray?.length) {
      return (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Ver mais
        </button>
      );
    } else if (criancas && numImages < criancasArray?.length) {
      return (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Ver mais
        </button>
      );
    }
  };

  const showMoreImages = () => {
    setNumImages(numImages + 20);
  };

  const handleClickShowAll = () => {
    navigate("/Fotos?filtro=all");
    setDataSelected(data?.allFotografia);
    setShowAll(true);
    setRetrato(false);
    setPromocionais(false);
    setGravidez(false);
    setRecemNascido(false);
    setBatizado(false);
    setCasamento(false);
    setActiveShowAll(true);
    setActiveRetrato(false);
    setActivePromocionais(false);
    setActiveBatizado(false);
    setActiveCasamento(false);
    setActiveGravidez(false);
    setActiveRecemNascido(false);
    setActiveCriancas(false);
    setNumImages(20);
  };
  const handleClickRetrato = () => {
    navigate("/Fotos?filtro=retrato");
    setDataSelected(retratoArray);
    setShowAll(false);
    setRetrato(true);
    setPromocionais(false);
    setGravidez(false);
    setRecemNascido(false);
    setBatizado(false);
    setCasamento(false);
    setActiveShowAll(false);
    setActiveRetrato(true);
    setActivePromocionais(false);
    setActiveBatizado(false);
    setActiveCasamento(false);
    setActiveGravidez(false);
    setActiveRecemNascido(false);
    setActiveCriancas(false);
    setNumImages(20);
  };

  const handleClickPromocionais = () => {
    navigate("/Fotos?filtro=promocionais");
    setDataSelected(promocionaisArray);
    setShowAll(false);
    setRetrato(false);
    setPromocionais(true);
    setGravidez(false);
    setRecemNascido(false);
    setBatizado(false);
    setCasamento(false);
    setActiveShowAll(false);
    setActiveRetrato(false);
    setActivePromocionais(true);
    setActiveBatizado(false);
    setActiveCasamento(false);
    setActiveGravidez(false);
    setActiveRecemNascido(false);
    setActiveCriancas(false);
    setNumImages(20);
  };

  const handleClickGravidez = () => {
    navigate("/Fotos?filtro=gravidez");
    setDataSelected(gravidezArray);
    setShowAll(false);
    setRetrato(false);
    setPromocionais(false);
    setGravidez(true);
    setRecemNascido(false);
    setBatizado(false);
    setCasamento(false);
    setActiveShowAll(false);
    setActiveRetrato(false);
    setActivePromocionais(false);
    setActiveBatizado(false);
    setActiveCasamento(false);
    setActiveGravidez(true);
    setActiveRecemNascido(false);
    setActiveCriancas(false);
    setNumImages(20);
  };

  const handleClickRecemNascido = () => {
    navigate("/Fotos?filtro=recem-nascido");
    setDataSelected(recemNascidoArray);
    setShowAll(false);
    setRetrato(false);
    setPromocionais(false);
    setGravidez(false);
    setRecemNascido(true);
    setBatizado(false);
    setCasamento(false);
    setActiveShowAll(false);
    setActiveRetrato(false);
    setActivePromocionais(false);
    setActiveBatizado(false);
    setActiveCasamento(false);
    setActiveGravidez(false);
    setActiveRecemNascido(true);
    setActiveCriancas(false);
    setNumImages(20);
  };

  const handleClickBatizado = () => {
    navigate("/Fotos?filtro=batizado");
    setDataSelected(batizadoArray);
    setShowAll(false);
    setRetrato(false);
    setPromocionais(false);
    setGravidez(false);
    setRecemNascido(false);
    setBatizado(true);
    setCasamento(false);
    setActiveShowAll(false);
    setActiveRetrato(false);
    setActivePromocionais(false);
    setActiveBatizado(true);
    setActiveCasamento(false);
    setActiveGravidez(false);
    setActiveRecemNascido(false);
    setActiveCriancas(false);
    setNumImages(20);
  };

  const handleClickCasamento = () => {
    navigate("/Fotos?filtro=casamento");
    setDataSelected(casamentoArray);
    setShowAll(false);
    setRetrato(false);
    setPromocionais(false);
    setGravidez(false);
    setRecemNascido(false);
    setBatizado(false);
    setCasamento(true);
    setActiveShowAll(false);
    setActiveRetrato(false);
    setActivePromocionais(false);
    setActiveBatizado(false);
    setActiveCasamento(true);
    setActiveGravidez(false);
    setActiveRecemNascido(false);
    setActiveCriancas(false);
    setNumImages(20);
  };

  const handleClickCriancas = () => {
    navigate("/Fotos?filtro=criancas");
    setDataSelected(criancasArray);
    setShowAll(false);
    setRetrato(false);
    setPromocionais(false);
    setGravidez(false);
    setRecemNascido(false);
    setBatizado(false);
    setCasamento(false);
    setCriancas(true);
    setActiveShowAll(false);
    setActiveRetrato(false);
    setActivePromocionais(false);
    setActiveBatizado(false);
    setActiveCasamento(false);
    setActiveGravidez(false);
    setActiveRecemNascido(false);
    setActiveCriancas(true);
    setNumImages(20);
  };

  //set dataSelected to allFotografia on load
  useEffect(() => {
    window.scrollTo(0, 0);
    setDataSelected(data?.allFotografia);

    if (filtro === "retrato") {
      handleClickRetrato();
    } else if (filtro === "promocionais") {
      handleClickPromocionais();
    } else if (filtro === "gravidez") {
      handleClickGravidez();
    } else if (filtro === "recem-nascido") {
      handleClickRecemNascido();
    } else if (filtro === "batizado") {
      handleClickBatizado();
    } else if (filtro === "casamento") {
      handleClickCasamento();
    } else if (filtro === "criancas") {
      handleClickCriancas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.allFotografia, filtro]);

  function openModal(index) {
    setModalIndex(index);
    setModalOpen(true);
    disableScroll.on();
  }

  function closeModal() {
    setModalOpen(false);
    disableScroll.off();
  }

  const ImageComponent = ({ url, width, height, index }) => {
    return (
      <img
        onClick={() => openModal(index)}
        className={` ${width > height ? styles.horizontal : ""} `}
        key={index}
        src={url}
        alt={""}
      />
    );
  };

  return (
    <>
      <div className={styles.buttonSection}>
        <button
          className={
            activeShowAll ? styles.buttonActive : styles.buttonDesactive
          }
          onClick={handleClickShowAll}
        >
          Todas...
        </button>

        <button
          className={
            activeRetrato ? styles.buttonActive : styles.buttonDesactive
          }
          onClick={handleClickRetrato}
        >
          Retratos
        </button>
        <button
          className={
            activeBatizado ? styles.buttonActive : styles.buttonDesactive
          }
          onClick={handleClickBatizado}
        >
          Batizados
        </button>
        <button
          className={
            activeGravidez ? styles.buttonActive : styles.buttonDesactive
          }
          onClick={handleClickGravidez}
        >
          Gravidez
        </button>
        <button
          className={
            activeCriancas ? styles.buttonActive : styles.buttonDesactive
          }
          onClick={handleClickCriancas}
        >
          Crianças
        </button>
        <button
          className={
            activeRecemNascido ? styles.buttonActive : styles.buttonDesactive
          }
          onClick={handleClickRecemNascido}
        >
          Recém-nascidos
        </button>

        <button
          className={
            activeCasamento ? styles.buttonActive : styles.buttonDesactive
          }
          onClick={handleClickCasamento}
        >
          Casamentos
        </button>
        {/* <button
          className={
            activePromocionais ? styles.buttonActive : styles.buttonDesactive
          }
          onClick={handleClickPromocionais}
        >
          Promocionais
        </button> */}
      </div>

      <div className={styles.galeriaGrid}>
        {modalOpen && (
          <Modal
            images={dataSelected}
            startIndex={modalIndex}
            onClose={closeModal}
          />
        )}
        {data?.allFotografia ? (
          (showAll &&
            data?.allFotografia.slice(0, numImages).map((foto, index) => {
              return (
                <>
                  <ImageComponent
                    url={foto.image.url}
                    width={foto.image?.width}
                    height={foto.image?.height}
                    id={foto._id}
                    index={index}
                    key={index}
                  />
                </>
              );
            })) ||
          (retrato &&
            retratoArray.slice(0, numImages).map((foto, index) => {
              return (
                <>
                  <ImageComponent
                    url={foto.image.url}
                    width={foto.image?.width}
                    height={foto.image?.height}
                    id={foto._id}
                    index={index}
                    key={index}
                  />
                </>
              );
            })) ||
          // (promocionais &&
          //   promocionaisArray.slice(0, numImages).map((foto, index) => {
          //     return (
          //       <>
          //         <ImageComponent
          //           url={foto.image.url}
          //           width={foto.image?.width}
          //           height={foto.image?.height}
          //           id={foto._id}
          //           index={index}
          //           key={index}
          //         />
          //       </>
          //     );
          //   })) ||
          (gravidez &&
            gravidezArray.slice(0, numImages).map((foto, index) => {
              return (
                <>
                  <ImageComponent
                    url={foto.image.url}
                    width={foto.image?.width}
                    height={foto.image?.height}
                    id={foto._id}
                    index={index}
                    key={index}
                  />
                </>
              );
            })) ||
          (recemNascido &&
            recemNascidoArray.slice(0, numImages).map((foto, index) => {
              return (
                <>
                  <ImageComponent
                    url={foto.image.url}
                    width={foto.image?.width}
                    height={foto.image?.height}
                    id={foto._id}
                    index={index}
                    key={index}
                  />
                </>
              );
            })) ||
          (batizado &&
            batizadoArray.slice(0, numImages).map((foto, index) => {
              return (
                <>
                  <ImageComponent
                    url={foto.image.url}
                    width={foto.image?.width}
                    height={foto.image?.height}
                    id={foto._id}
                    index={index}
                    key={index}
                  />
                </>
              );
            })) ||
          (casamento &&
            casamentoArray.slice(0, numImages).map((foto, index) => {
              return (
                <>
                  <ImageComponent
                    url={foto.image.url}
                    width={foto.image?.width}
                    height={foto.image?.height}
                    id={foto._id}
                    index={index}
                    key={index}
                  />
                </>
              );
            })) ||
          (criancas &&
            criancasArray.slice(0, numImages).map((foto, index) => {
              return (
                <>
                  <ImageComponent
                    url={foto.image.url}
                    width={foto.image?.width}
                    height={foto.image?.height}
                    id={foto._id}
                    index={index}
                    key={index}
                  />
                </>
              );
            }))
        ) : (
          <div>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
      {/* {showAll && numImages < data?.allFotografia.length && (
        
      )} */}

      {/* {showAll && numImages < data?.allFotografia?.length ? (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Mostrar mais imagens
        </button>
      ) : (
        ""
      )}

      {retrato && numImages < retratoArray?.length ? (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Mostrar mais imagens
        </button>
      ) : (
        ""
      )}

      {batizado && numImages < batizadoArray?.length ? (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Mostrar mais imagens
        </button>
      ) : (
        ""
      )}
      {gravidez && numImages < gravidezArray?.length ? (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Mostrar mais imagens
        </button>
      ) : (
        ""
      )}
      {criancas && numImages < criancasArray?.length ? (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Mostrar mais imagens
        </button>
      ) : (
        ""
      )}
      {recemNascido && numImages < recemNascidoArray?.length ? (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Mostrar mais imagens
        </button>
      ) : (
        ""
      )}
      {casamento && numImages < casamentoArray?.length ? (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Mostrar mais imagens
        </button>
      ) : (
        ""
      )}
      {promocionais && numImages < promocionaisArray?.length ? (
        <button className={styles.moreImagesBtn} onClick={showMoreImages}>
          Mostrar mais imagens
        </button>
      ) : (
        ""
      )} */}
      <ButtonComponent />
    </>
  );
};

export default GaleriaFotos;
