import React, { useEffect } from "react";
import Layout from "../Components/UI/Layout";
import styles from "../Styles/Pages/Albuns.module.css";
import FotoAlbuns1 from "../Assets/Images/albuns/_LI_2809.jpg"; //cortar
import FotoAlbuns2 from "../Assets/Images/albuns/_LI_2804.jpg";
import FotoAlbuns3 from "../Assets/Images/albuns/_LI_2808.jpg";
import FotoAlbuns4 from "../Assets/Images/albuns/_LI_7566.jpg";
import FotoAlbuns5 from "../Assets/Images/albuns/_LI_7563.jpg";
import { Link } from "react-router-dom";
import Messenger from "../Components/UI/Messenger";
import Seo from "../Components/Seo";
import { motion } from "framer-motion";

const Albuns = () => {
  //scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout>
        <Seo siteTitle="Álbuns" />
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.albunsPage}
        >
          <section className={styles.header}>
            <h1>Álbuns</h1>
            <span>Momentos únicos preservados em cada página.</span>
          </section>
          <div className={styles.grid3}>
            <img src={FotoAlbuns1} alt="" />
            <img src={FotoAlbuns2} alt="" />
          </div>
          <div className={styles.grid2}>
            <p>
              ESTE SERVIÇO É CONCEBIDO EXCLUSIVAMENTE POR NÓS, DESDE OS
              PORMENORES DA EXECUÇÃO, À GRAVAÇÃO DO ÁLBUM, CRIANDO ASSIM A NOSSA
              PRÓPRIA IDENTIDADE. O ESTILO RÚSTICO É O QUE CARACTERIZA OS NOSSOS
              ÁLBUNS.
            </p>
            <img src={FotoAlbuns3} alt="" />
          </div>
          <div className={styles.spanBtnSection}>
            <h4>Vamos Criar Algo Fantástico</h4>
            <Link to="/contactos">
              <button>Saber Mais</button>
            </Link>
          </div>
          <div className={styles.grid3}>
            <img src={FotoAlbuns5} alt="" />
            <img src={FotoAlbuns4} alt="" />
          </div>
        </motion.main>
      </Layout>
      <Messenger />
    </>
  );
};

export default Albuns;
