/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import styles from "../Styles/Components/LojaAnimacao.module.css";

const LojaAnimacao = () => {
  const canvasRef = useRef(null);
  const frameCount = 19; // Altere o número de imagens dos frames aqui
  const frames = Array.from(
    { length: frameCount },
    (_, i) => `/loja/frame${i + 1}.jpg`
  );

  const [currentFrame, setCurrentFrame] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const propsAnimated = useSpring({ opacity: 1, from: { opacity: 0 } });

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const img = new Image();
    img.src = frames[currentFrame];
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, 0, 0);
    };

    const handleWheel = (event) => {
      const delta = Math.sign(event.deltaY);
      console.log(delta);

      if (delta > 0 && currentFrame < frameCount - 1) {
        setCurrentFrame((prevFrame) => prevFrame + 1);
      } else if (delta < 0 && currentFrame > 0) {
        setCurrentFrame((prevFrame) => prevFrame - 1);
      }

      if (currentFrame < 17 && window.scrollY > 0) {
        setIsPlaying(true);
      } else if (currentFrame > 17 && window.scrollY > 0) {
        setIsPlaying(false);
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("touchmove", handleWheel, {
      passive: false,
    });

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchmove", handleWheel);
    };
  }, [currentFrame, frames, frameCount]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <animated.canvas
        ref={canvasRef}
        id="hero-lightpass"
        className={styles.canvasLoja}
        style={{
          ...propsAnimated,
          width: "100%",
          height: "100%",
          transform: "none",
          padding: "1rem 0",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      ></animated.canvas>
    </div>
  );
};

export default LojaAnimacao;
