import React from "react";
import { motion } from "framer-motion";

const VideoHeroNew = () => {
  return (
    <>
      <header className="section default-header full-height all-devices home-header">
        <div className="single-vimeo-background is-inview">
          <iframe
            title="video"
            src="https://player.vimeo.com/video/812442933?api=1&background=1&autoplay=1&loop=1&autopause=0"
            frameBorder="0"
            allow=" autoplay; encrypted-media;"
            width={640}
            height={360}
            data-ready="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
        {/* <div className="shape-polygon">
          <div className="shape-polygon-inner">
            <div class="single-vimeo-background is-inview">
              <iframe
                title="video"
                src="https://player.vimeo.com/video/812442933?api=1&background=1&autoplay=1&loop=1&autopause=0"
                frameBorder="0"
                allow=" autoplay; encrypted-media;"
                width={640}
                height={360}
                data-ready="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </div>
          </div>
        </div> */}
        {/* <motion.h1
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 1.5, ease: "easeIn" }}
        >
          Sérgio Gonçalves
        </motion.h1> */}

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2, delay: 2, ease: "easeIn" }}
          className="mousey"
        >
          <div class="scroller"></div>
        </motion.div>
      </header>
    </>
  );
};

export default VideoHeroNew;
