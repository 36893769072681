import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

import Sobre from "./Pages/Sobre";
import Albuns from "./Pages/Albuns";
import Videos from "./Pages/Videos";
import Fotos from "./Pages/Fotos";
import Espacoloja from "./Pages/Espacoloja";
import Contactos from "./Pages/Contactos";
import NotFoundPage from "./Pages/404";
// import Cliente from "./Pages/Cliente";
import Teste from "./Pages/teste";

const root = ReactDOM.createRoot(document.getElementById("root"));
const client = new ApolloClient({
  uri: "https://1yk2giex.api.sanity.io/v1/graphql/production/default",

  cache: new InMemoryCache(),
});
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
document.oncontextmenu = function (e) {
  e.preventDefault();
};
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/Sobre" element={<Sobre />} />
        <Route path="/Albuns" element={<Albuns />} />
        <Route path="/Videos" element={<Videos />} />
        <Route path="/Fotos" element={<Fotos />} />
        <Route path="/Espacoloja" element={<Espacoloja />} />
        <Route path="/Contactos" element={<Contactos />} />
        <Route path="/teste" element={<Teste />} />
        <Route path="*" element={<NotFoundPage />} />
        {/* <Route path="/cliente" element={<Cliente />} /> */}
      </Routes>
    </BrowserRouter>
  </ApolloProvider>
);
