import React, { useState, useEffect } from "react";
import styles from "../../Styles/UI/ModalRecents.module.css";

const ModalRecents = (props) => {
  const [currentImage, setCurrentImage] = useState(props.startIndex);
  function closeModal() {
    setCurrentImage(props.startIndex);
    props.onClose();
  }

  //add event listener on arrow keys to change image  (left and right)

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "ArrowLeft") {
        setCurrentImage(
          currentImage === 0 ? props.images.length - 1 : currentImage - 1
        );
      } else if (e.key === "ArrowRight") {
        setCurrentImage(
          currentImage === props.images.length - 1 ? 0 : currentImage + 1
        );
      }
    });
    return () => {
      document.removeEventListener("keydown", (e) => {
        if (e.key === "ArrowLeft") {
          setCurrentImage(
            currentImage === 0 ? props.images.length - 1 : currentImage - 1
          );
        } else if (e.key === "ArrowRight") {
          setCurrentImage(
            currentImage === props.images.length - 1 ? 0 : currentImage + 1
          );
        }
      });
    };
  }, [currentImage, props.images.length]);

  return (
    <div className={styles.modal}>
      <div className={styles.modalCenter}>
        <img src={props.images[currentImage].image.url} alt={""} />
      </div>
      <button
        className={styles.back}
        onClick={() =>
          setCurrentImage(
            currentImage === 0 ? props.images.length - 1 : currentImage - 1
          )
        }
      >
        &#60;
      </button>
      <button
        className={styles.next}
        onClick={() =>
          setCurrentImage(
            currentImage === props.images.length - 1 ? 0 : currentImage + 1
          )
        }
      >
        &#62;
      </button>
      <button className={styles.close} onClick={closeModal}>
        X
      </button>
    </div>
  );
};

export default ModalRecents;
