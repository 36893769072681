/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import Seo from "../Components/Seo";
import { Link } from "react-router-dom";
// import YouTube from "react-youtube";
import { useQuery, gql } from "@apollo/client";
import Layout from "../Components/UI/Layout";
import styles from "../Styles/Pages/Videos.module.css";
import Messenger from "../Components/UI/Messenger";
import { motion } from "framer-motion";

const videosData = gql`
  query {
    allVideo {
      title
      data
      texto
      videoUrl
      _id
    }
  }
`;
const Videos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onPlayerReady = (e) => {
    e.target.pauseVideo();
  };
  const opts = {
    height: "310",
    width: "350",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      controls: 1,
      showinfo: 0,
      rel: 1,
      modestbranding: 0,
      h1: "pt",
      iv_load_policy: 3,
    },
  };
  const { data } = useQuery(videosData);

  return (
    <>
      <Layout>
        <Seo siteTitle="Videos" />
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.videosPage}
        >
          <section className={styles.header}>
            <h1>Vídeos</h1>
            <span>
              Cada momento é único, merece ser captado com arte e criatividade.
            </span>
          </section>
          <section className={styles.sectionVideo}>
            {data?.allVideo ? (
              data?.allVideo &&
              data?.allVideo.map((video) => (
                <div className={styles.videoGrid} key={video._id}>
                  {/* <YouTube
                    videoId={video.videoUrl}
                    opts={opts}
                    onReady={onPlayerReady}
                  /> */}
                  <iframe
                    title="video"
                    src={`https://player.vimeo.com/video/${video.videoUrl}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    width={640}
                    height={360}
                    // data-ready="true"
                    // webkitallowfullscreen="true"
                    // mozallowfullscreen="true"
                    // allowFullScreen
                  />
                  <div className={styles.gridText}>
                    <span>{video.data}</span>
                    <span>{video.title}</span>
                    {/* <p>{video.texto}</p> */}
                  </div>
                </div>
              ))
            ) : (
              <div>
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </section>
        </motion.main>

        <div className={styles.spanBtnSection}>
          <h4>Vamos Criar Algo Fantástico</h4>
          <Link to="/contactos">
            <button>Contacta-me</button>
          </Link>
        </div>
      </Layout>
      <Messenger />
    </>
  );
};

export default Videos;
