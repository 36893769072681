import React from "react";
import styles from "../../Styles/UI/Footer.module.css";
import { Icon } from "@iconify/react";
import LogoFooter from "../../Assets/Images/LogoFooter.png";

const Footer = () => {
  return (
    <>
      <footer className={styles.footerMain}>
        <div className={styles.logoFooterSection}>
          <img className={styles.logoFooter} src={LogoFooter} alt="" />
          <div className={styles.navIcons}>
            <a href="https://www.facebook.com/sergiogfotografiaa">
              <Icon
                className={styles.ico}
                icon="entypo-social:facebook-with-circle"
              />
            </a>
            <a href="https://www.instagram.com/sergiogoncalvesphoto/">
              <Icon
                className={styles.ico}
                icon="entypo-social:instagram-with-circle"
              />
            </a>
            <a href="https://wa.me/936654236">
              <Icon
                className={`styles.ico ${styles.whatsapp}`}
                icon="dashicons:whatsapp"
              />
            </a>
          </div>
        </div>
        <span className={styles.spanMain}>
          Copyright © 2022- Criado por{" "}
          <span className={styles.developedbycarlos}>
            <a href="https://inovee.pt">Inovee Agência Digital</a>
          </span>
        </span>
      </footer>
    </>
  );
};

export default Footer;
