import React from "react";
import styles from "../../Styles/UI/Messenger.module.css";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";

const Messenger = () => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{
        delay: 0.5,
        duration: 0.7,
        ease: [0.17, 0.67, 0.83, 0.67],
      }}
    >
      <a
        href="https://wa.me/936654236"
        className={`${styles.whatsappFloat} ${styles.pulse} `}
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <i class="fa fa-whatsapp whatsapp-icon "></i> */}
        {/* <Icon
          icon="fa-brands:facebook-messenger"
          className={` ${styles.faWhatsapp} ${styles.whatsappIcon} `}
        /> */}
        <Icon
          icon="logos:whatsapp-icon"
          className={` ${styles.faWhatsapp} ${styles.whatsappIcon} `}
        />
      </a>
    </motion.div>
  );
};

export default Messenger;
