/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import Layout from "../Components/UI/Layout";
import styles from "../Styles/Pages/Espacoloja.module.css";
import FotoGrid1 from "../Assets/Images/loja/_LI_5053.jpg";
import FotoGrid2 from "../Assets/Images/loja/_LI_5009.jpg";
import FotoGrid3 from "../Assets/Images/loja/_LI_5097.jpg";
import FotoGrid4 from "../Assets/Images/loja/_LI_5070.jpg";
import FotoGrid5 from "../Assets/Images/loja/_LI_5019.jpg";
import FotoGrid6 from "../Assets/Images/loja/_LI_5013.jpg";
import Messenger from "../Components/UI/Messenger";
import Seo from "../Components/Seo";
import { motion } from "framer-motion";
import LojaAnimacao from "../Components/LojaAnimacao";
import disableScroll from "disable-scroll";

const Espacoloja = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // useEffect(() => {
  //   const mouseWheel = (event) => {
  //     const delta = Math.sign(event.deltaY);
  //     console.log(delta);
  //     if (delta <= 246) {
  //       disableScroll.on();
  //     } else {
  //       disableScroll.off();
  //     }
  //   };
  //   window.addEventListener("wheel", mouseWheel, { passive: false });
  //   return () => {
  //     window.removeEventListener("wheel", mouseWheel);
  //   };
  // }, []);

  return (
    <>
      <Layout>
        <Seo siteTitle="Espaço Loja" />
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.espacolojaPage}
        >
          {/* <section className={styles.header}>
            <h1>Espaço Loja</h1>
            <span>Visita-me, vais adorar o espaço!</span>
          </section> */}

          <LojaAnimacao />

          <p className={styles.lojaParagrafo}>
            O Nosso Espaço foi criado com a ambição de ser original e com uma
            identidade própria tendo sido realizado exclusivamente por nós e
            desafiando-nos a executar tudo ao pormenor, reutilizando diversos
            materiais de forma criativa para dar vida a peças exclusivas,
            tornando a loja única e original.
          </p>

          <section className={styles.grid}>
            <img src={FotoGrid6} alt="" />
            <img src={FotoGrid2} alt="" className="fotoLoja2" />
            <img src={FotoGrid3} alt="" />
            <img src={FotoGrid4} alt="" className="fotoLoja4" />
            <img src={FotoGrid5} alt="" />
            <img src={FotoGrid1} alt="" />
          </section>

          <div className={styles.morada}>
            <h3>Estamos Situados Em...</h3>
            <span>
              Rua Conselheiro Rocha peixoto nº2 4980-626 ponte da barca
            </span>
          </div>
          <div className={styles.spanBtnSection}>
            <h4>Visita-me</h4>
            <a href="https://www.google.com/maps/place/Estr.+Nacional+2,+4980-614+Pte.+da+Barca/@41.808449,-8.420415,19z/data=!4m5!3m4!1s0xd2508e0254200f3:0xa6925c22277b52ac!8m2!3d41.8084489!4d-8.420415?hl=pt-PT">
              <button>Saber Mais</button>
            </a>
          </div>
        </motion.main>
      </Layout>
      <Messenger />
    </>
  );
};

export default Espacoloja;
