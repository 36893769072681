import React from 'react';
import { Helmet } from 'react-helmet';
import FavIcon from '../Assets/Images/ico.png';
const siteMetaData = {
  title: 'Sérgio Gonçalves Fotografia & Video',
  description:
    'Sérgio Gonçalves Fotografia & Video - Breve introdução ao site ainda por definir',
  contact: 'sergiogoncfotografia@gmail.com',
  url: 'https://sergiofototest.netlify.com',
  image: '../Assets/Images/sergio-foto-facebook-banner.png',
  author: 'Carlos Barros',
  authorContact: 'carlosbwebdev@gmail.com',
};

const Seo = ({ siteTitle, pageDesc }) => {
  const { title, description, contact, url, image, author, authorContact } =
    siteMetaData;

  return (
    <Helmet title={`${siteTitle} | ${title}`} htmlAttributes={{ lang: 'pt' }}>
      <meta name="description" content={pageDesc ? pageDesc : description} />
      <meta
        name="keywords"
        content="fotografia,video,sessões fotográficas, batizados, casamentos, bebés, produtos, foto, retratos, maternidade,sergio goncalves fotografia"
      />
      <meta name="image" content={image} />
      <meta name="contact" content={contact} />
      <meta name="url" content={url} />
      <meta name="author" content={author} />
      <meta name="author-contact" content={authorContact} />
      <link rel="icon" type="image/png" href={FavIcon} sizes="16x16" />
    </Helmet>
  );
};

export default Seo;
