/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
// import Layout from "../../src/Components/UI/Layout";

const Teste = () => {
  const canvasRef = useRef(null);
  const frameCount = 148;
  const frames = [
    "/loja/frame1.jpg",
    "/loja/frame2.jpg",
    "/loja/frame3.jpg",
    "/loja/frame4.jpg",
    "/loja/frame5.jpg",
    "/loja/frame6.jpg",
    "/loja/frame7.jpg",
    "/loja/frame8.jpg",
    "/loja/frame9.jpg",
    "/loja/frame10.jpg",
    "/loja/frame11.jpg",
    "/loja/frame12.jpg",
    "/loja/frame13.jpg",
    "/loja/frame14.jpg",
    "/loja/frame15.jpg",
    "/loja/frame16.jpg",
    "/loja/frame17.jpg",
    "/loja/frame18.jpg",
    "/loja/frame19.jpg",

    // Adicione os caminhos para as outras imagens dos frames
  ];
  const [currentFrame, setCurrentFrame] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [canScroll, setCanScroll] = useState(false); // Novo estado para controlar o avanço da página
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const img = new Image();
    img.src = frames[currentFrame];
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, 0, 0);
    };

    const handleWheel = (event) => {
      if (isAnimating) {
        event.preventDefault(); // Impede a rolagem da página durante a animação
        return;
      }

      const delta = Math.sign(event.deltaY);

      if (delta > 0 && currentFrame < frameCount - 1) {
        setCurrentFrame((prevFrame) => prevFrame + 1);
      } else if (delta < 0 && currentFrame > 0) {
        setCurrentFrame((prevFrame) => prevFrame - 1);
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [currentFrame, frames, isAnimating]);

  useEffect(() => {
    if (currentFrame === frames.length - 1) {
      setIsAnimating(false); // Quando a animação estiver completa, permite a rolagem normal
      setCanScroll(true); // Permite o avanço da página quando a animação estiver completa
    }
  }, [currentFrame, frames]);

  const handleCanvasClick = () => {
    if (currentFrame === frames.length - 1) {
      setIsAnimating(true); // Inicia a animação ao clicar no canvas
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        overflow: canScroll ? "auto" : "hidden", // Controla o overflow da página
      }}
    >
      <animated.canvas
        ref={canvasRef}
        id="hero-lightpass"
        style={{
          ...props,
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
        onClick={handleCanvasClick}
      ></animated.canvas>
    </div>
  );
};

export default Teste;
