import React, { useState, useEffect } from "react";
import Layout from "../Components/UI/Layout";
import styles from "../Styles/Pages/Contactos.module.css";
import FotoBio from "../Assets/Images/Ficheiro13.jpg";
import Messenger from "../Components/UI/Messenger";
import Seo from "../Components/Seo";
import { motion } from "framer-motion";

const Contactos = () => {
  const [selected, setSelected] = useState("Opções");
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout>
        <Seo siteTitle="Contactos" />
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.contactosPage}
        >
          <section className={styles.header}>
            <h1>Estamos ao seu dispor</h1>
            <span>Em que podemos ajudar ?</span>
          </section>
          <section className={styles.sectionContact}>
            <img src={FotoBio} alt="" />
            <form
              action="https://formspree.io/f/xqkjbzlv"
              method="POST"
              className={styles.contactFormSection}
            >
              <label>Nome:</label>
              <input name="Nome:" type="text" />
              <label>Contactos:</label>
              <input name="Contacto Telefónico:" type="number" />
              <label>Email:</label>
              <input name="Email:" type="email" />
              <label>Escolha:</label>
              <select name="Assunto" onChange={handleChange}>
                <option defaultValue={selected}>Opções</option>
                <option value="Albuns">Àlbuns</option>
                <option value="Retrato">Retrato</option>
                <option value="Sessão Grávida">Sessão Grávida</option>
                <option value="Batizado">Batizado</option>
              </select>
              <label>Mensagem:</label>
              <textarea
                name="Mensagem:"
                rows="8"
                cols="50"
                placeholder="Escrever Mensagem..."
              ></textarea>
              <button type="submit">Enviar</button>
            </form>
          </section>
        </motion.main>
      </Layout>
      <Messenger />
    </>
  );
};

export default Contactos;
