import React from "react";
import { Link } from "react-router-dom";
import disableScroll from "disable-scroll";

import styles from "../../Styles/UI/Sidebar.module.css";
import { Icon } from "@iconify/react";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  if (!isOpen) {
    disableScroll.off();
  }
  return (
    <aside className={`${styles.sidebar} ${isOpen ? styles.showSidebar : ""}`}>
      <button className={styles.closeBtn} onClick={toggleSidebar}>
        <Icon icon="bi:x" />
      </button>

      <div>
        <ul className={`${isOpen ? styles.sidebarLinks : ""}`}>
          <li>
            <Link activestyle={{ color: "rgb(236, 105, 85" }} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link activestyle={{ color: "rgb(236, 105, 85" }} to="/sobre">
              Sobre
            </Link>
          </li>
          <li>
            <Link activestyle={{ color: "rgb(236, 105, 85" }} to="/fotos">
              Portefólio
            </Link>
          </li>
          <li>
            <Link activestyle={{ color: "rgb(236, 105, 85" }} to="/videos">
              Vídeos
            </Link>
          </li>
          <li>
            <Link activestyle={{ color: "rgb(236, 105, 85" }} to="/espacoloja">
              Espaço Loja
            </Link>
          </li>
          <li>
            <Link activestyle={{ color: "rgb(236, 105, 85" }} to="/albuns">
              Álbuns
            </Link>
          </li>
          <li>
            <Link activestyle={{ color: "rgb(236, 105, 85" }} to="/contactos">
              Contactos
            </Link>
          </li>
        </ul>
      </div>
      <div className={`${isOpen ? styles.sidebarIcons : ""}`}>
        <a href="https://www.facebook.com/sergiogfotografiaa">
          <Icon
            className={styles.ico}
            icon="entypo-social:facebook-with-circle"
          />
        </a>
        <a href="https://www.instagram.com/sergiogoncalvesphoto/">
          <Icon
            className={styles.ico}
            icon="entypo-social:instagram-with-circle"
          />
        </a>
        <a href="https://wa.me/936654236">
          <Icon
            className={`styles.ico ${styles.whatsapp}`}
            icon="dashicons:whatsapp"
          />
        </a>
      </div>
    </aside>
  );
};

export default Sidebar;
