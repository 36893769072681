import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "../../Styles/UI/Navbar.module.css";
import SemiSquareLeft from "../../Assets/Images/navbarL.png";
import { Icon } from "@iconify/react";

const Navbar = ({ toggleSidebar }) => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);
  return (
    <nav className={scroll ? styles.navbarBlack : styles.navbar}>
      <div className={styles.navbarCenter}>
        <div className={styles.navLogo}>
          <Link to="/">
            <h1>Sérgio Gonçalves</h1>
            <div className={styles.navLogoBottom}>
              <img src={SemiSquareLeft} className={styles.squareLeft} alt="" />
              <span>fotografia & vídeo</span>
              <img src={SemiSquareLeft} className={styles.squareRight} alt="" />
            </div>
          </Link>
        </div>
        <div className={styles.LinksGrid}>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "	#A9A9A9" : "",
                fontWeight: isActive ? "600" : "",
                borderBottom: isActive ? "1px solid #A9A9A9" : "",
              };
            }}
            to="/"
          >
            Home
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "	#A9A9A9" : "",
                fontWeight: isActive ? "600" : "",
                borderBottom: isActive ? "1px solid #A9A9A9" : "",
              };
            }}
            to="/sobre"
          >
            Sobre
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "	#A9A9A9" : "",
                fontWeight: isActive ? "600" : "",
                borderBottom: isActive ? "1px solid #A9A9A9" : "",
              };
            }}
            to="/fotos"
          >
            Portefólio
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "	#A9A9A9" : "",
                fontWeight: isActive ? "600" : "",
                borderBottom: isActive ? "1px solid #A9A9A9" : "",
              };
            }}
            to="/videos"
          >
            Vídeos
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "	#A9A9A9" : "",
                fontWeight: isActive ? "600" : "",
                borderBottom: isActive ? "1px solid #A9A9A9" : "",
              };
            }}
            to="/albuns"
          >
            Álbuns
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "	#A9A9A9" : "",
                fontWeight: isActive ? "600" : "",
                borderBottom: isActive ? "1px solid #A9A9A9" : "",
              };
            }}
            to="/espacoloja"
          >
            Espaço Loja
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "	#A9A9A9" : "",
                fontWeight: isActive ? "600" : "",
                borderBottom: isActive ? "1px solid #A9A9A9" : "",
              };
            }}
            to="/contactos"
          >
            Contactos
          </NavLink>
        </div>
        <div className={styles.mobileMenu} onClick={toggleSidebar}>
          <Icon icon="ep:menu" />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
