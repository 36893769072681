import React, { useEffect } from "react";
import Layout from "../Components/UI/Layout";
import styles from "../Styles/Pages/Fotos.module.css";
import Messenger from "../Components/UI/Messenger";
import Seo from "../Components/Seo";
import GaleriaFotos from "../Components/GaleriaFotos";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";

const Fotos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Seo siteTitle="Fotos" />
          <main className={styles.fotosPage}>
            <section className={styles.header}>
              <h1>Fotografias</h1>
              <span>A viagem de tantas histórias, captadas num instante.</span>
            </section>
          </main>
          <GaleriaFotos />
          <div className={styles.socialMedia}>
            <h4>Segue-me nas Redes Sociais!</h4>
            <div className={styles.Icons}>
              <a href="https://www.facebook.com/sergiogfotografiaa">
                <Icon
                  className={styles.ico}
                  icon="entypo-social:facebook-with-circle"
                />
              </a>
              <a href="https://www.instagram.com/sergiogoncalvesphoto/">
                <Icon
                  className={styles.ico}
                  icon="entypo-social:instagram-with-circle"
                />
              </a>
              <a href="https://wa.me/936654236">
                <Icon
                  className={`styles.ico ${styles.whatsapp}`}
                  icon="dashicons:whatsapp"
                />
              </a>
            </div>
          </div>
          <Messenger />
        </motion.div>
      </Layout>
    </>
  );
};

export default Fotos;
